<template>
  <header class="header">
    <div class="container">
      <div class="header__box">
        <div class="header__nav">
          <Logo />
          <Nav />
        </div>
        <div class="header__nav">
          <a
            :href="$route.path == '/ld/instructions' ? '#' : '/ld/instructions'"
            class="instr-link"
            :class="$route.path == '/ld/instructions' ? 'active' : ''"
          >
            <svg
              width="22"
              height="25"
              viewBox="0 0 22 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.2143 3.10903H17.2857V0.779617C17.2857 0.469028 17.1286 0.15844 16.8143 0.0807932C16.5 -0.0745009 16.1857 0.00314613 15.95 0.236087C13.2 2.95373 4.08571 3.18668 0.785714 3.10903C0.628571 3.10903 0.392857 3.18668 0.235714 3.34197C0.0785714 3.49726 0 3.65256 0 3.8855V24.0737C0 24.462 0.314286 24.8502 0.785714 24.8502H3.22143H21.2143C21.6857 24.8502 22 24.5396 22 24.0737V3.8855C22 3.41962 21.6857 3.10903 21.2143 3.10903ZM1.57143 23.2973V4.66197C4.08571 4.66197 11.7071 4.58432 15.7143 2.33256V20.6573C13.3571 22.6761 7.22857 23.2196 3.22143 23.2973H2.35714H1.57143ZM20.4286 23.2973H13.9071C15.1643 22.8314 16.2643 22.2879 17.05 21.5114C17.2071 21.3561 17.2857 21.2008 17.2857 20.9679V4.66197H20.4286V23.2973ZM4.78018 12.0975C4.34777 12.1339 3.96773 11.8129 3.93134 11.3805C3.89495 10.9481 4.21598 10.568 4.64839 10.5316C8.86238 10.177 11.7699 9.77638 13.3389 9.34025C13.757 9.22404 14.1902 9.46876 14.3064 9.88684C14.4226 10.3049 14.1779 10.7381 13.7598 10.8543C12.0694 11.3241 9.08688 11.7351 4.78018 12.0975ZM3.93134 17.5922C3.96773 18.0246 4.34777 18.3457 4.78018 18.3093C9.08688 17.9468 12.0694 17.5359 13.7598 17.066C14.1779 16.9498 14.4226 16.5167 14.3064 16.0986C14.1902 15.6805 13.757 15.4358 13.3389 15.552C11.7699 15.9881 8.86238 16.3887 4.64839 16.7434C4.21598 16.7798 3.89495 17.1598 3.93134 17.5922Z"
                :fill="$route.path == '/instructions' ? '#7C8792' : 'white'"
              />
            </svg>
            <span v-if="device == 'desktop'">Инструкции по оформлению</span>
          </a>
          <ButtonNav
            @click="isOpenMenu = !isOpenMenu"
            :class="{ open: isOpenMenu }"
          />
        </div>
      </div>
    </div>
    <NavMobile
      @close="isOpenMenu = false"
      :class="isOpenMenu ? 'nav-open' : 'nav-close'"
    />
  </header>
</template>

<script>
import Logo from "./Logo.vue";
import Nav from "./Nav.vue";
import ButtonNav from "./ButtonNav.vue";
import NavMobile from "./NavMobile.vue";

export default {
  components: { Logo, Nav, ButtonNav, NavMobile },
  name: "Header",
  data() {
    return {
      isOpenMenu: false,
    };
  },
  watch: {
    isOpenMenu(val) {
      if (val) document.body.style.overflowY = "hidden";
      else document.body.style.overflowY = "auto";
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 45px;
  background: var(--mainColor);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  &__nav {
    display: flex;
    align-items: center;
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  & .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .nav-close {
    opacity: 0;
    transform: translateX(100%);
  }
  .nav-open {
    opacity: 1;
    transform: translateX(0%);
  }
  .instr-link {
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
    }
    span {
      font-size: 13px;
      line-height: 18px;
      color: #ffffff;
    }
    &.active {
      cursor: auto;
      span {
        color: #7c8792;
      }
    }
  }
}
</style>
